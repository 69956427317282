import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: center;
`;

const HomePage = () => {
  return (
    <Container>
      <h1>به سایت تولید لایسنس خوش آمدید</h1>
      <p>برای دریافت لایسنس، لطفا از طریق سیستم پرداخت اقدام نمایید.</p>
    </Container>
  );
};

export default HomePage;