// src/utils/api.js

const generateLicense = async (courseCode) => {
  const randomNumber = Math.floor(Math.random() * 1000000000).toString().padStart(9, '0');
  const name = `AT${randomNumber}`;
  const watermark = `AT${randomNumber}`;

  const data = {
      course: [courseCode],
      name: name,
      watermark: { texts: [{ text: watermark }] }
  };

  try {
      const response = await fetch('/.netlify/functions/generateLicense', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
      });

      if (!response.ok) {
          throw new Error('Failed to generate license');
      }

      const result = await response.json();
      return result.key;
  } catch (error) {
      console.error('Error generating license:', error);
      throw error;
  }
};

export { generateLicense };
