import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const Th = styled.th`
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

const AdminPage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [licenses, setLicenses] = useState([]);

  useEffect(() => {
    // In a real application, you would fetch this data from a server
    // For now, we'll use dummy data
    setLicenses([
      { timestamp: '2023-05-01 10:30:00', course: 'TOEFL', license: 'TOEFL-123456' },
      { timestamp: '2023-05-02 11:45:00', course: 'GRE', license: 'GRE-789012' },
    ]);
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    if (username === 'user123' && password === 'pass123') {
      setIsAuthenticated(true);
    } else {
      alert('Invalid credentials');
    }
  };

  if (!isAuthenticated) {
    return (
      <Container>
        <h1>Admin Login</h1>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Login</button>
        </form>
      </Container>
    );
  }

  return (
    <Container>
      <h1>Admin Dashboard</h1>
      <Table>
        <thead>
          <tr>
            <Th>Timestamp</Th>
            <Th>Course</Th>
            <Th>License</Th>
          </tr>
        </thead>
        <tbody>
          {licenses.map((license, index) => (
            <tr key={index}>
              <Td>{license.timestamp}</Td>
              <Td>{license.course}</Td>
              <Td>{license.license}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default AdminPage;