import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const GMATRedirect = () => {
  const navigate = useNavigate();
  const { setIsAuthorized } = useAuth();

  useEffect(() => {
    setIsAuthorized(true);
    navigate('/gmat-license');
  }, [navigate, setIsAuthorized]);

  return null;
};

export default GMATRedirect;