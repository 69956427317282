import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const DuolingoRedirect = () => {
  const navigate = useNavigate();
  const { setIsAuthorized } = useAuth();

  useEffect(() => {
    setIsAuthorized(true);
    navigate('/duolingo-license');
  }, [navigate, setIsAuthorized]);

  return null;
};

export default DuolingoRedirect;