import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import HomePage from './pages/HomePage';
import TOEFLRedirect from './pages/RedirectPages/TOEFLRedirect';
import GRERedirect from './pages/RedirectPages/GRERedirect';
import IELTSRedirect from './pages/RedirectPages/IELTSRedirect';
import OETRedirect from './pages/RedirectPages/OETRedirect';
import DuolingoRedirect from './pages/RedirectPages/DuolingoRedirect';
import GMATRedirect from './pages/RedirectPages/GMATRedirect';
import TOEFLLicense from './pages/UserPages/TOEFLLicense';
import GRELicense from './pages/UserPages/GRELicense';
import IELTSLicense from './pages/UserPages/IELTSLicense';
import OETLicense from './pages/UserPages/OETLicense';
import DuolingoLicense from './pages/UserPages/DuolingoLicense';
import GMATLicense from './pages/UserPages/GMATLicense';
import AdminPage from './pages/AdminPage';

function App() {
  return (
    <AuthProvider>
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/toefl-redirect" element={<TOEFLRedirect />} />
                <Route path="/gre-redirect" element={<GRERedirect />} />
                <Route path="/ielts-redirect" element={<IELTSRedirect />} />
                <Route path="/oet-redirect" element={<OETRedirect />} />
                <Route path="/duolingo-redirect" element={<DuolingoRedirect />} />
                <Route path="/gmat-redirect" element={<GMATRedirect />} />
                <Route path="/toefl-license" element={<TOEFLLicense />} />
                <Route path="/gre-license" element={<GRELicense />} />
                <Route path="/ielts-license" element={<IELTSLicense />} />
                <Route path="/oet-license" element={<OETLicense />} />
                <Route path="/duolingo-license" element={<DuolingoLicense />} />
                <Route path="/gmat-license" element={<GMATLicense />} />
                <Route path="/admin" element={<AdminPage />} />
            </Routes>
        </Router>
    </AuthProvider>
  );
}

export default App;